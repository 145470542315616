import React from 'react'
import { useCallback } from "react";
import Particles from 'react-tsparticles';
import {loadFull} from "tsparticles";

function ParticleBg() {
    const particlesInit = useCallback(async engine => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
  return (
    <Particles 
    id="tsparticles" 
    init={particlesInit}
    loaded={particlesLoaded}
    options={{
        "fullScreen": {
            "enable": true,
            "zIndex": 1
        },
        "particles": {
            "number": {
                "value": 100,
                "density": {
                    "enable": true,
                    "value_area": 400
                },
                "limit": {
                    "mode": "delete",
                    "value": 50 
                }
            },
            "color": {
                "value": "#709BBF"
            },
            "shape": {
                "type": "circle",
                "options": {
                    "sides": 6
                }
            },
            "opacity": {
                "value": 0.8,
                "random": false,
                "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.1,
                    "sync": false
                }
            },
            "size": {
                "value":{
                    "min":0.5,
                    "max":1
                },
                "random": false,
                "anim": {
                    "enable": false,
                    "speed": 20,
                    "size_min": 10,
                    "sync": false
                }
            },
            "rotate": {
                "value": 0,
                "random": true,
                "direction": "clockwise",
                "animation": {
                    "enable": true,
                    "speed": 4,
                    "sync": false
                }
            },
            "line_linked": {
                "enable": true,
                "distance": 60,
                "color": "#709BBF",
                "opacity": 0.2,
                "width": 1
            },
            "move": {
                "enable": true,
                "speed": 1,
                "direction": "none",
                "random": true,
                "straight": false,
                "out_mode": "out",
                "attract": {
                    "enable": true,
                    "rotateX": 1000,
                    "rotateY": 1200
                }
            }
        },
        "interactivity": {
            "events": {
                "onhover": {
                    "enable": true,
                    "mode": ["connect"]
                },
                "onclick": {
                    "enable": false,
                    "mode": "bubble"
                },
                "resize": true
            },
            "modes": {
                "connect":{
                    "distance":50,
                    "links":{
                        "opacity":0.2,
                    },
                    "radius":100

                },
                "grab": {
                    "distance": 400,
                    "line_linked": {
                        "opacity": 1
                    }
                },
                "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 3
                },
                "repulse": {
                    "distance": 200
                },
                "push": {
                    "particles_nb": 4
                },
                "remove": {
                    "particles_nb": 2
                }
            }
        },
        "retina_detect": true,
        "background": {
            "color": '#0F1B35',
            "image": "",
            "position": "10% 50%",
            "repeat": "no-repeat",
            "size": "cover"
        }
    }}
    />
  )
}

export default ParticleBg