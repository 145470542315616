import React, { Component } from 'react'
import '../../resources/css/style.css'
import CompanyIntro from "../../Components/CompanyIntro/CompanyIntro"
import SubHeader from '../../Components/SubHeader/SubHeader';
import img1 from "../../images/aboutImg/about-pic-a.png";
import img2 from "../../images/aboutImg/about-pic-b.png";
import img3 from "../../images/aboutImg/about-pic-c.png";
import Grid from '@mui/material/Grid';
import Header from '../Header/header'
import homeApi from '../../Api/homeApi';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fade from '@mui/material/Fade';
import { BiCaretDown } from "react-icons/bi";
import './About.css'

export class About extends Component {

	constructor() {
		super();
		this.state = {
			ourValues: []
		}
	}
	
	async componentDidMount() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		const response = await homeApi.getOurValues();
		let data = response?.data
		console.log("response of ourValues", data);
		data = data?.map((ourValue) => {
			return {...ourValue, flag: false}
		})
		console.log("data",data);
		await this.setState({ ...this.state, ourValues: data})
		console.log("key", this.state)

	}
	handleExpansion(index, flag) {
		console.log("flag", flag, "i", index);
		let ourValues = this.state['ourValues']
		ourValues[index]["flag"] = flag;
		this.setState({...this.state, ourValues: ourValues})
	}
	render() {
		let headerName = window.location.pathname;
		return (
			<div>
				<Header id="header" />
				<SubHeader name={headerName} />
				{/* company intro */}
				<CompanyIntro />
				{/* our-values */}
				
				{/* <section className="our-value paddingAbout light-bg"> */}
					<div className="container-fluid our-value paddingAbout lightBackgound">
						<div className="position-relative" style={{paddingLeft:'2vw'}}>
							<span className="over-span">Values</span>
							<Grid container spacing={2} columns={2}>
							{/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"> */}
							<Grid item xs={1} md={1} lg={1}>
								<div className="services-main">
									<div className="comm-para comm-Top-Para aos-init aos-animate" data-aos="fade-up">
										<div className="over-heading">
											<div className="overSpan">Our Values</div>
										</div>
										<p className=""></p><p>Our software outsourcing company has determined, and continues to follow, a particular set of values.
											 These values have formed our internal corporate culture, individual attitude toward every client,
											  our business sustainability, as well as environmentally-friendly approaches. Our principles are as follows:</p>
										<p></p>
									</div>
								</div>
							{/* </div> */}
							</Grid>
							{/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"> */}
							<Grid item xs={1} md={1} lg={1} sm={1}>
								<div className="value-inner" >
									<div className="accordion" id="values-inner">
										{this.state.ourValues.map((item, index) => (
											<Accordion
												expanded={item.flag}
												onChange={(event, flag) => this.handleExpansion(index, flag)}
												slots={{ transition: Fade }}
												slotprops={{ transition: { timeout: 400 } }}
												style={{marginBottom:'7vh'}}
												sx={{
													'& .MuiAccordion-region': { height: item.flag ? 'auto' : 0 },
													'& .MuiAccordionDetails-root': { display: item.flag ? 'block' : 'none' },
												}}
											>
												<AccordionSummary className='panel1header'
													expandIcon={<BiCaretDown />}
													aria-controls="panel1-content"
													id="panel1-header" style={{margin: '0px 0px'}}
												>
												
													<span className='secure-header'> {item.header}</span>

												</AccordionSummary>
												<AccordionDetails>{item.content}.</AccordionDetails>
											</Accordion>
										))
										
										}
										<div>{this.state.ourValues.length >= 0}</div>
									</div>
								</div>
							{/* </div> */}
							</Grid>
							</Grid>
						</div>
					</div>
				{/* </section> */}
				
				{/* about-four-sec */}
				{/* <section className="about-four-sec padding-full dark-bg"> */}
				<Grid spacing={2} columnSpacing={1} columnGap={1} >
					<div className=" padding-full dark-bg" id="comMission">
						<div className="row">
							<Grid container
								direction="row"
								justifyContent="space-between"
								alignItems="center">
								<Grid item xs={6}>
									<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 about-four">
										<div className="fourSec comm-para">

											<div className="bg_image1 fourOur_heading" style={{ position: 'relative' }}>
												<img src={img1} id="img1" alt="" /><span>Our Mission</span></div>
											<p></p><p></p>
											<p>We intend to offer impeccable, tangible, and business-friendly digital marketing and IT solutions to expose our clients’ businesses to the transforming digital age. We strive hard to make sure a strong digital presence of your brand. Only building websites is not our mission, we create websites from scratch that sells.</p>
											<p></p>
										</div>
									</div>
								</Grid>
								<Grid item xs={6}>
									<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 about-four">
										<div className="four-sect comm-para">

											<div className="bg_image2 fourOur_heading"><img src={img2} id="img2" alt="" /><span>Our Vision</span></div>
											<p></p><p>We aim to use our expertise and disciplined approach to create meaningful and long-lasting relationships with our clients. With the help of our efficacious IT solutions and target-oriented digital marketing strategies, we aim to take your business to the whole new level of success.</p>
											<p></p>
										</div>
									</div>
								</Grid>
							</Grid>
							<Grid container
								direction="row"
								justifyContent="center"
								alignItems="center">

								<Grid item xs={6}>

									<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 about-four">
										<div className="fourSec comm-para">
											<div className="bg_image3 fourOur_heading"><img src={img3} id="img3" alt="" /><span>Our Philosophy</span></div>
											<p></p><p>To create memorable, innovative, transformative, responsive, digitally ambitious, and improvised business solutions for our clients. Offering satisfactory and sustainable IT solutions for the growth of your business is our primary goal.</p>
											<p></p>
										</div>
									</div>
								</Grid>
								<Grid item xs={6}>
									<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 about-four">
										<div className="four-sect comm-para">
											<div className="bg_image4 fourOur_heading"><img src={img3} id="img3" alt="" /><span>Our Strategy</span></div>
											<p></p><p>We use our expertise in creating highly advanced, enthusiastic, and business-friendly IT and digital solutions. We have a highly diligent and proficient team with extensive knowledge in the digital domain to come up with smart and effective strategies for your business.</p>
											<p></p>
										</div>
									</div>
								</Grid>
							</Grid>
						
						</div>
					</div>
					</Grid>
				{/* </section> */}
				{/* newslatter */}
				{/* <Newslatter /> */}
			</div>
		)
	}
}

export default About