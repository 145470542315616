
var webData={};
var intro = {};
function getWebData(){
    return webData;
}

function setWebsiteData(data){
    webData = data;
    // console.log("fundata", webData)
}
function getWeAreData(){
    return webData.who_are_we_items
}

function getComIntro(){
    intro = {
        who_are_we_primary: webData.who_are_we_primary,
        who_are_we_secondary: webData.who_are_we_secondary
    }
    return intro
}
export default {setWebsiteData, getWebData, getWeAreData, getComIntro}