import React from 'react';
import Home from '../src/pages/Home/home.jsx';
import Footer from '../src/pages/Footer/footer.jsx';
import About from './pages/About/About.jsx';
import Services from './pages/Services/Services.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contactus from "./pages/ContactUs/contactUs.jsx";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './fonts.css';
const theme = createTheme({
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: '2.25rem',
      lineHeight: 2.75,
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.625rem',
      lineHeight: 2.125, 
    }
  }
});

const App = () => {
 
  // console.log(window.location.pathname);
 
  
  return (
    <ThemeProvider theme={theme}>
     <Router>
     <Routes>
       <Route exact path="/" element={< Home />} />
       <Route path="/home" element={<Home />} />
       <Route path="/about" element={<About />} />
       <Route path="/service" element={<Services />} />
       <Route path="/contactUs" element={<Contactus />} />
     </Routes>
      <Footer />
   </Router>
   </ThemeProvider>
  );
};

export default App;
