import axios from 'axios';

const instance = axios.create({
  // baseURL:'http://127.0.0.1:8000/api',
    // baseURL: 'http://192.168.1.9:8000/api',
    baseURL: '/api',
  });
  
export default instance

