import React, { Component, Fragment} from 'react';
import Box from '@mui/material/Box';
import './header.css';
import { BiLogoFacebookCircle, BiLogoTwitter, BiLogoInstagram, BiLogoLinkedin, BiPhoneCall, BiLogoGmail } from "react-icons/bi";
import { Grid } from '@mui/material';
import Navbar from '../../Components/NavBar/Navbar';
import ParticleBg from '../../Components/ParticleBg';
import homeApi from '../../Api/homeApi';

class Header extends Component {

    constructor(){
        super();
        this.state={
            intro:{}
        }
    }

    componentDidMount() {
            homeApi.webDetails().then( res =>{
                this.setState({...this.state, intro: res.data});
              },
              err=>{
                console.log("err",err);
              })
        
    }
  
    render() {
     

             let isHomePage = (window.location.pathname === '/Home' || window.location.pathname === '/' );
                return (
            <>
            <Fragment >
                { isHomePage && <ParticleBg /> }
                <Box style={{ zIndex: 1, position: 'relative', marginBottom:'-14px' }}>
                    <Grid container spacing={6} columns={16}>
                        <Grid item xs={8} sx={{ paddingLeft: '12px !important' }}>
                            <ul>
                                <li style={{ padding: '0px 9px' }}>
                                    <BiPhoneCall size="20" color="white" className="phone" />
                                </li>
                                <li>
                                    <a href="tel:+916283009504">
                                        <span className='left_icon_header'>{this.state.intro.contact}</span></a>
                                </li>
                                <li style={{ padding: '0px 6px 0px 2rem' }}>
                                    <BiLogoGmail size="20" color="white" className="gmail" />
                                </li>
                                <li>
                                    <a href={`mailto:${this.state.intro.primary_headline}`}>
                                        <span className='left_icon_header'>{this.state.intro.primary_email}</span></a>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={8} gap={2}>
                            <div style={{ float: 'right', marginTop: '0rem' ,color: 'white'}}>
                            <a href={`http://www.facebook.com/${this.state.intro.facebook_profile}`}>
                                <BiLogoFacebookCircle size="25" color="white" className="fb" />                             
                                </a>
                                <a href={`https://twitter.com/?lang=en/${this.state.intro.twitter_profile}`}>
                                <BiLogoTwitter size="20" color="white" className="twitter" />
                                </a>
                                <a href={`http://www.instagram.com/${this.state.intro.instagram_profile}`}>
                                <BiLogoInstagram size="25" color="white" className="insta" />
                                </a>
                                <a href={`${this.state.intro.linked_profile}`}>
                                <BiLogoLinkedin size="25" color="white" className="linkedin" />
                                </a>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <hr style={{ position: 'relative', zIndex: 1 ,color: 'white'}} />
                <Navbar style={{ position: 'relative ', zIndex: 1, color: 'white' }} />
                
                {/*slide up effect https://stackoverflow.com/questions/72469234/slide-up-animation-for-text-using-css-only */}

            </Fragment>
            </>

        );
    }
};

export default Header;