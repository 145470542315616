import instance from "../Config/basicConfig";

const webDetails = async () => {
    return callApi(async () => { return await instance.get('/web_config/') });
}
const getServices = async () => {
    return callApi(async () => { return await instance.get('/services/') });
}
const getAllReview = async () => {
    return callApi(async () => { return await instance.get('/all_reviews/') });
}
const getOurValues = async () => {
    return callApi(async () => { return await instance.get('/web_config_our_values/') });
}
const getAchievements = async () => {
    return callApi(async () => { return await instance.get('/web_config_achievements/') });
}

const postContactUs = async(data) => {
    return callApi(async () => { return await instance.post('/contact_us/',data) });
}
async function callApi(fun) {
    try {
        let response = await fun();
        if (response.name === "AxiosError")
            response = response.response;
        if (response.status === 422 || response.status === 401) {
            return { authFailed: true, data: null, message: "" }
        }
        else if (response.status === 200) {
            // console.log("called")
            return { authFailed: false, data: response.data, message: "success" }
        }
        else if(response.status === 201){
            return { authFailed: false, data: response.data, message: "success" }
        }
        console.log("API response", response);
        return { authFailed: false, data: null, message: "API failed with status: " + response.status }
    } catch (error) {
        console.log("Error: ", error);
        return { authFailed: false, data: null, message: "API failed with error: " + error }
    }
}
export default { webDetails,getServices,getAllReview , getOurValues, getAchievements, postContactUs};