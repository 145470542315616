import React, { Component, Fragment } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fade from '@mui/material/Fade';
import { BiCaretDown } from "react-icons/bi";
import Grid from '@mui/material/Grid';
import './custmerChoose.css';
export default class CustomerChoose extends Component {
  constructor() {
    super();
    this.state = {
      ourExperties: [
        { flag: false, header: 'Expert Advisors', content: 'We are a well known Web design and development company in Mohali, Helping large and small scale industries alike.' },
        { flag: false, header: '24/7 SUPPORT', content: 'No matter your project requirement, We are available 24/7 a week and can help you in resolving the related queries.' },
        { flag: false, header: 'Secure Service', content: 'We are well known in developing quality Web Applications that can help your business running swiftly.' }
      ]
    }
  }

  handleExpansion(index, flag) {
    console.log("flag", flag, "i", index);
    let ourExperties = this.state['ourExperties']
    ourExperties[index]["flag"] = flag;
    this.setState({ ...this.state, ourExperties: ourExperties })
  }

  render() {
    return (
      <Fragment>
        <section id="chooseSection" className=" chooseSection-padding">
          <div className="container-fluid">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {this.state.ourExperties.map((item, index) => (
                  <Accordion
                    expanded={item.flag}
                    onChange={(event, flag) => this.handleExpansion(index, flag)}
                    slots={{ transition: Fade }}
                    slotprops={{ transition: { timeout: 400 } }}
                    sx={{
                      '& .MuiAccordion-region': { height: item.flag ? 'auto' : 0 },
                      '& .MuiAccordionDetails-root': { display: item.flag ? 'block' : 'none' },
                    }}
                  >
                    <AccordionSummary className='panel1header'
                      expandIcon={<BiCaretDown />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >

                      <span className='secure-header'> {item.header}</span>

                    </AccordionSummary>
                    <AccordionDetails>{item.content}.</AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>

                <div className="row align-items-center">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="comm-para choose-content ml-5 home-strong aos-init aos-animate" data-aos="fade-up">
                      <div className="home-heading">Why Clients Trust STR Softwares?</div>
                      <p className=""></p><h3>STR Softwares is one of the most effective Web Design Company getting online is easy.</h3>
                      <p>Succeeding online is a different story. You’ll need more than just a beautiful website to stand out these days. Online marketing solutions.</p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </Fragment>
    )
  }
}
