import React, { Component } from 'react'
import './keyTech.css';

export class KeyTech extends Component {
  render() {
    return (
      <div>
        <section className="keytech-section padding-full dark-bg">
        <div className="container-fluid">
          <div className="row">


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="services-main">
                <div className="services-content aos-init aos-animate paddingLeft" data-aos="fade-up">
                  <div className="over-heading">
                    <span className="over-span">Expertise</span>
                    <h2 className="session-heading4 textWhite ">Key Tech Expertise</h2>
                  </div>
                  <p className="comm-para textWhite">We analyze your product concept and business specifics, and bind these conclusions with industry standards, market trends, and appropriate tech stacks. Our software development firm builds top products for a particular industry, namely:</p>
                </div>
              </div>


            </div>

          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default KeyTech