import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import './newslatter.css';
export class Newslatter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      phoneNumber: '',
      email: '',
      message: '',
      errors: {},
    };
  }

  validateForm = () => {
    const { firstName, phoneNumber, email, message } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!firstName) {
      formIsValid = false;
      errors["firstName"] = "First name is required";
    }

    if (!phoneNumber) {
      formIsValid = false;
      errors["phoneNumber"] = "Phone number is required";
    }

    if (!email) {
      formIsValid = false;
      errors["email"] = "Email is required";
    } else if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      formIsValid = false;
      errors["email"] = "Enter a valid email address";
    }

    if (!message) {
      formIsValid = false;
      errors["message"] = "Message is required";
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      console.log(this.state);
    }
  }
  render() {
    const { firstName, phoneNumber, email, message, errors } = this.state;
    const inputStyle = {
      border: '0px solid transparent',
      borderRadius: '0px',
      padding: '1px',
      outline: 'none'
    };
    return (
      <div>

        <section className="touch-section padding-full">
          <div className="container-fluid">
            <Grid container>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="text-center comm-para mt-2 mb-md-5 mb-lg-5 mb-xl-5 mb-sm-4 mb-3 aos-init" >
                  <div id="newsLtrHeading">Let’s Get In Touch</div>
                  <p style={{textAlign:'center'}}>We use digital to help businesses transform, find customers and stay relevant. Let’s connect!</p>
                </div>
                <div className="contact-form" id="contactForm">

                  <div className="wpcf7 js" id="wpcf7-f77-o1" lang="en-US" dir="ltr">
                    <div className="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p> <ul></ul></div>
                    {/* <form action="/#wpcf7-f77-o1" method="post" className="wpcf7-form init" aria-label="Contact form" noValidate="novalidate" data-status="init"> */}
                      <div style={{ display: 'none' }}>
                        <input type="hidden" name="_wpcf7" value="77" />
                        <input type="hidden" name="_wpcf7_version" value="5.8.6" />
                        <input type="hidden" name="_wpcf7_locale" value="en_US" />
                        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f77-o1" />
                        <input type="hidden" name="_wpcf7_container_post" value="0" />
                        <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                      </div>
                      <Container maxWidth="lg">
                  <Box
                    component="form" fullWidth
                    onSubmit={this.handleSubmit}
                    noValidate
                    sx={{ mt: 3 }}
                  >
                    <TextField             
                      label="First Name"
                      margin="normal"
                      spacing sx={{ mb: 2, mr:1}}
                      name="firstName"
                      value={firstName}
                      onChange={this.handleChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />

                    <TextField
                      spacing sx={{ mb: 2, mx:2}}
                      label="Phone Number"
                      margin="normal"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={this.handleChange}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                    />

                    <TextField
                      sx={{ mb: 2, mx:1, ml:1}}
                      label="Email"
                      margin="normal"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />

                    <TextField
                      fullWidth
                      sx={{mr:4}}
                      style={{border:'0px solid transparent'}}
                      label="Message"
                      margin="normal"
                      name="message"
                      value={message}
                      onChange={this.handleChange}
                      
                      rows={4}
                      error={!!errors.message}
                      helperText={errors.message}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2,width: '200px', mx:35 }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Container>
                    {/* </form> */}
                  </div>
                </div>
              
              </div>
            </Grid>
          </div>
        </section>
      </div>
    )
  }
}

export default Newslatter