import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import homeApi from '../../Api/homeApi';
import commonFunction from '../commonFunction';
import './CompanyIntro.css'

export class CompanyIntro extends Component {

    constructor() {
        super();
        this.state = {
            comFun: [],
            comIntro: {}
        }
    }

    async componentDidMount() {
        const response = await homeApi.webDetails();
        // console.log(response);
        await commonFunction.setWebsiteData(response.data);
        let comFun = await commonFunction.getWeAreData();
        let comIntro = await commonFunction.getComIntro();
        await this.setState({ ...this.state, comFun: comFun, comIntro: comIntro });
    }
    render() {
        return (
            <div>
                <section className="who-we-section padding-full body ">
                    <div className="container-fluid">
                        <Grid container spacing={3} columns={2}>
                            <Grid  xs={1} sm={7} md={9} lg={1} style={{paddingLeft:'5vw'}}>
                                <div className=" home-strong who-left home-strong comm-para">
                                    <div className="home-heading">
                                       Get to Know Us
                                    </div>
                                    <h3>

                                        {this.state.comIntro.who_are_we_primary}
                                    </h3>
                                    <p>
                                        {this.state.comIntro.who_are_we_secondary}.
                                    </p>
                                 </div>
                            </Grid>
                            <Grid container columns={2} xs={1} sm={5} md={3} lg={1} style={{ paddingLeft: '10vw'}}>
                                {/* <div className="who-right"> */}
                                {
                                    this.state.comFun?.map((item, index) => (
                                        <Grid item key={index} sm={2} md={2} lg={1}>

                                            <div className="who-calc first-who" id="cardTop" style={{marginTop: index % 2 === 0 ? '32px' : '0' }}>
                                                <div className="who_count"  style={{ margin: '2rem', paddingLeft: '1rem' }}>
                                                    {item.header} <br />
                                                    <p style={{ color: 'white' }}>{item.content}</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </div>
                </section>
            </div>
        )
    }
}

export default CompanyIntro