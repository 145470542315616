import React, { Component } from 'react'
import { Grid } from '@mui/material';
import SubHeader from '../../Components/SubHeader/SubHeader';
import Header from '../Header/header';
import homeApi from '../../Api/homeApi';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import './contactUs.css';
export default class contactUs extends Component {

	constructor() {
		super();
		this.state = {
			name: '',
			email: '',
			phone: '',
			designation: 'Select Developement',
			message: '',
			open: false,
			showMsg: '',
			showProgress: false
		}

	}

  async componentDidMount() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	async handleSubmit() {
		console.log("state", this.state);
		this.setState({ ...this.state, showProgress: true });
		let formdata = {
			name: this.state.name,
			email: this.state.email,
			phone: this.state.phone,
			designation: this.state.designation,
			message: this.state.message
		}

		const response = await homeApi.postContactUs(formdata);
		console.log(response);
		if (response.message === 'success') {
			this.setState({ ...this.state, open: true, showMsg: "Form Submitted Successfully!", showProgress: false });
			setTimeout(
				this.reSetForm(),
				2000);
		} else {
			return null
		}
	}

	reSetForm() {
		let blankState = {
			name: '',
			email: '',
			phone: '',
			designation: 'Select Developement',
			message: '',
			open: false,
			showMsg: '',
			showProgress: false
		}
		console.log("reset")
		this.setState({ ...this.state, blankState })
	}
	handleClose() {
		this.setState({ ...this.state, open: false });
	}
	handleChange(event) {
		// setAge(event.target.value);
		this.setState({ ...this.state, age: event.target.value });
	};
	render() {
		let headerName = window.location.pathname;
		console.log("path", window.location.href)
		const fragments = window.location.href.split("#");
		const fragmentParams = fragments[1]; // Get the fragment part after the '#'
		console.log(fragmentParams);
		return (
			<div>
				<Header />
				<SubHeader name={headerName} />
				<section className="contact_section padding-full">
					<div className="container-fluid">
						<div className="row no-gutters">
							<Snackbar
								open={this.state.open}
								autoHideDuration={5000}
								onClose={this.handleClose.bind(this)}
								message={this.state.showMsg}
								ContentProps={{
									sx: {
										background: "#341a81", color: '#fff'
									}
								}}
							/>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<div className="contact_info contact_info_height">
										<div className="home-heading">Contact Info</div>
										<p style={{ color: '#fff' }}>We can help you at every step for increased revenues and profits. With us, you can advance confidently in the right direction.</p>
										<div className="contact_inf">

											<i className="fas fa-phone-volume"></i>
											<a className="textWhite" href="tel:+919055554411">
												+919055554411</a>

										</div>

										<div className="contact_inf">

											<i className="fas fa-envelope"></i>
											<a className="textWhite" href="mailto:info@strsoftwares.com">
												info@strsoftwares.com</a>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<div id="contactId" className="contact_detail aos-init aos-animate" data-aos="fade-up">
										<div className="contact-form contact_info_height">
											<div className='contact_Form_Heading'>Fill the form and send your query</div>


											<div className="wpcf7 js" id="wpcf7-f460-o1" lang="en-US" dir="ltr">
												<div className="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p> <ul></ul></div>
												<form style={{ maxWidth: 400, margin: 'auto' }}>
													<TextField
														label="Name"
														variant="outlined"
														fullWidth
														margin="normal"
														value={this.state.name}
														onChange={async (e) => await this.setState({ ...this.state, name: e.target.value })}
													/>
													<TextField
														label="Email"
														variant="outlined"
														fullWidth
														margin="normal"
														value={this.state.email}
														onChange={async (e) => await this.setState({ ...this.state, email: e.target.value })}
													/>
													<TextField
														label="Phone Number"
														variant="outlined"
														fullWidth
														margin="normal"
														value={this.state.phone}
														onChange={async (e) => await this.setState({ ...this.state, phone: e.target.value })}
													/>
													<FormControl fullWidth margin="normal" variant="outlined">
														<InputLabel>Select Option</InputLabel>
														<Select
															value={this.state.designation}
															onChange={async (e) => await this.setState({ ...this.state, designation: e.target.value })}
															label="Select Option"
															autoWidth={true}
															MenuProps={{
																MenuListProps: {
																	style: {
																		maxWidth: 400,
																	}
																}
															}}
															children={
																["Froentend Developement", "Backend Developement", "ML Developement", "Web Scraping", "Api Developement",].map((item, index) => {
																	return <MenuItem key={index} value={item} style={{ width: '100%'}}>{item}</MenuItem>
																})
															}
														/>
													</FormControl>

													<TextField
														label="Message"
														multiline
														rows={4}
														variant="outlined"
														fullWidth
														margin="normal"
														value={this.state.message}
														onChange={async (e) => this.setState({ ...this.state, message: e.target.value })}
													/>
													<Button type="button"  sx={{ mt: 2,width: '200px', mx:10 }} variant="contained" size="large" color="primary" onClick={this.handleSubmit.bind(this)}>
														Submit
													</Button>
													{this.state.showProgress && <CircularProgress color="secondary" style={{ marginLeft: '3rem' }} />}
												</form>
											</div>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
