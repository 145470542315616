import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import { Link, NavLink } from 'react-router-dom'
import './footer.css';
import '../../resources/css/style.css';
import homeApi from '../../Api/homeApi';
import img from '../../images/logo.png';
import { BiLogoFacebookCircle, BiLogoTwitter, BiLogoInstagram, BiLogoLinkedin } from "react-icons/bi";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      socialMediaInfo: {}
    }
  }
  componentDidMount() {
    homeApi.webDetails().then(res => {
      this.setState({ ...this.state, socialMediaInfo: res.data });
    },
      err => {
        console.log("err", err);
      })

  }
  render() {
    return (
      <div>
        <footer className="main-footer footer-padding">
          <div id="stars"></div><div id="stars2"></div><div id="stars3"></div>
          <Grid className='container-fluidFooter'>
                <Link to="/">
                  <img src={img} alt="" className="img-fluid" id="footerlogo" /></Link>
          </Grid>
                <p></p>
          <div className="container-fluidFooter">
            <Grid container spacing={2} direction="row"
              justifyContent="flex-start"
              alignItems="stretch">
              <Grid item xs={3} md={12}>
                <p></p>
              </Grid>

              <Grid item xs={3} md={4} style={{ paddingLeft: '4%' }}>
                <div className="footer-menus quick-menus">
                  <div className="footer_heading">Quick Link</div>
                  <ul className="footer-text">
                    <li>	
                      <div className="menu-footerheader-menu-container">
                        <ul id="menu-footerheader-menu" className="menu">
                          <li id="menu-item-390" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-390"> 
                            <NavLink to="/">Home</NavLink>
                          </li>
                          <li id="menu-item-391" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-391">
                              <NavLink to="/about">About</NavLink>
                          </li>
                          <li id="menu-item-395" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-395">
                            <NavLink to="/service">Services</NavLink>
                          </li>
                          <li id="menu-item-515" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-515">
                            <NavLink to="/contactUs">Contact Us</NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </Grid>

              <Grid item xs={3} md={4} >
                <div className="footer-menus service-menus">
                  <div className="footer_heading">Services</div>
                  <ul className="footer-text">

                    <li>
                      <div className="menu-footer-menu-container"><ul id="menu-footer-menu" className="menu">
                        <li id="menu-item-305" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-305">Froentend Developement</li>
                        <li id="menu-item-306" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-306">Backend Developement</li>
                        <li id="menu-item-307" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-307">ML Developement</li>
                        <li id="menu-item-308" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-308">Web Scraping</li>
                        <li id="menu-item-309" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-309">Api Developement</li>
                      </ul></div></li>
                  </ul>
                </div>
              </Grid>

              <Grid item xs={3} md={4} >
                <div className="footer-menus cont-menus">
                  <div className="footer_heading">Contact Us</div>
                  <ul className="footer-text">
                    <li className='menu-item'>MON-SAT: 10:00AM-7:00PM</li>
                  </ul>
                </div>

              </Grid>

            </Grid>
          </div>
        </footer>

        <div className="copyright ">
          <div className="container-fluid">
            <Grid container >
              <Grid item xs={6} md={6}>
                <div className="col-md-8 col-sm-12 mb-0">
                  <p>Copyright LLP 2008 STR softwares All Rights Reserved</p>
                </div>
              </Grid>
              <Grid item xs={6} md={6} >
                <div style={{ float: 'right', marginTop: '0rem', color: 'white' }}>
                  <a href={`http://www.facebook.com/${this.state.socialMediaInfo.facebook_profile}`}>
                    <BiLogoFacebookCircle size="25" color="white" className="fb" />
                  </a>
                  <a href={`https://twitter.com/?lang=en/${this.state.socialMediaInfo.twitter_profile}`}>
                    <BiLogoTwitter size="20" color="white" className="twitter" />
                  </a>
                  <a href={`http://www.instagram.com/${this.state.socialMediaInfo.instagram_profile}`}>
                    <BiLogoInstagram size="25" color="white" className="insta" />
                  </a>
                  <a href={`${this.state.socialMediaInfo.linked_profile}`}>
                    <BiLogoLinkedin size="25" color="white" className="linkedin" />
                  </a>
                </div>
              </Grid>
            </Grid>

          </div>
        </div>
      </div>
    )
  }
}

export default Footer;