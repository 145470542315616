import React, { Component } from 'react'

export default class SubHeader extends Component {

    render() {
        // console.log("subHeader",this.props.name)
        let headerName = this.props.name[1].toUpperCase() + this.props.name.slice(2)
        return (
            <div>
                <section className="home-banner dark-bg min-height-bnn">
                    {/* <span className="dot-background"></span> */}
                    <div id="stars"></div><div id="stars2"></div><div id="stars3"></div>
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="home-bnn-content">
                                    <h1>{headerName}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
