import React, { useState, useEffect, Fragment } from 'react'
import Grid from '@mui/material/Grid';
import homeApi from '../../Api/homeApi';
import { NavLink } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import './ourService.css'
import '../../resources/css/style.css';
function OurService() {
  const [service, setService] = useState();

  useEffect(() => {
    const serviceData = async () => {
      try {
        const res = await homeApi.getServices();
        let data = res?.data
        // console.log("data",data)
        data = data.services_list?.map((services_list) => {
          return { ...services_list, flag: false }
        })
        setService(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    serviceData();
  }, []);

  const handleOpen = async (index) => {
    console.log("print");
    let serviceList = [...service];
    serviceList[index]["flag"] = true;
    setService(serviceList);
    console.log("service", service)

  }
  const handleClose = async (index) => {
    console.log("close")
    let serviceList = [...service];
    serviceList[index]["flag"] = false;
    setService(serviceList)
  }
  return (

    <Fragment>
      <section id="ourService" className="our-services padding-up light-bg" >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="services-main justify-content-between align-items-center d-flex">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <div className="services-content aos-init aos-animate" data-aos="fade-up">
                      <div className="over-heading comm-para">
                        {/* <span className="over-span">Services</span> */}
                        <div className="home-heading"><h2 className='session-header'>Our Services</h2></div>
                      </div>
                      <p className="comm-paragraph mt-2 mb-5">Creating remarkable digital experiences and full-service web solutions.</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <NavLink to="/service"> <button type="button" className="btn-animation btn defult-btn btn-sm blue-solid-btn">View All Services</button></NavLink>
                    <div className="services-btn aos-init aos-animate" data-aos="fade-up">
                    </div>

                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className="row comm-padding">
            <Grid container spacing={2}>
              {service?.map((item, index) => {
                return (

                  <Grid item xs={12} md={3}>
                    <div className="service-part ux-serv">
                      <div className="hover-services">
                        <img src={item.logo} alt="" className="img-fluid hover-img" loading="lazy" />
                        <img src={item.logo} alt="" className="img-fluid out-hover" loading="lazy" />
                      </div>
                      <h4 id="h4Margin" style={{ height: '11vh' }}>{item.heading}</h4>
                      <p style={{ minHeight: '108px' }}>{item.description}</p>
                      <button style={{ margin: '0 20%' }} onClick={() => handleOpen(index)}>Read More</button>
                      <Modal
                        open={item.flag}
                        key={item.id}
                        onClose={() => handleClose(index)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        height="500px" width="100px" sx={{ height: '2rem' }}

                      >
                        <div style={{ backgroundColor: '#fff', height: '23rem', width: '30rem', borderStyle: 'groove' }} className='modalDiv'>

                          <div className="modalService" >
                            {item.heading}
                          </div> <br />
                          <p className='modalDescription'>{item.description}</p>
                        </div>

                      </Modal>
                    </div>
                  </Grid>
                )
              })}

            </Grid>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default OurService;