import React from 'react'
import { Button } from '@mui/material';
import './Banner.css'
import { NavLink } from 'react-router-dom';
const Banner = () => {

    return (
        <div>
            <div className='bannerText'>
                <h2 className='font-bold' id='bannerHead'>Digital Marketing Services That Deliver <span style={{ color: '#0c91b9' }}>Without Fail.</span></h2>
                <h4 className='quotes font-bold'>We’re a full-service digital marketing agency that combines digital marketing and design to help people connect, transact, and explore online.</h4>

                <div style={{textAlign: 'center', marginTop:'3rem',marginBottom:'2rem',justifyContent:'center'}}>
                <NavLink to='/contactUs'><Button className=' btn-animation btn defult-btn btn-sm blue-solid-btn bannerBtn'>Get Quote</Button></NavLink>
                {/* <div className="services-btn aos-init aos-animate" data-aos="fade-up">
                      <NavLink to="/service"> <button type="button" className="btn-animation btn defult-btn btn-sm gray-solid-btn">View All Services</button></NavLink>
                    </div> */}
                </div>
            </div>
        </div>
    )

}
export default Banner