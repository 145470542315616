import React, { Component } from 'react'
import SubHeader from '../../Components/SubHeader/SubHeader';
import Grid from '@mui/material/Grid';
import Header from '../Header/header';
import homeApi from '../../Api/homeApi';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import './Services.css'

export class Services extends Component {

  constructor() {
    super();
    this.state = {
      serviceList: []
    }
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const response = await homeApi.getServices();
    let data = response?.data
    console.log("data",data)
    data = data.services_list?.map((services_list) => {
			return {...services_list, flag: false}
		})
    await this.setState({ ...this.state, serviceList: data});
    console.log("state data", this.state);
  }

  async handleOpen(index,itemFlag){
   
    let serviceList = this.state['serviceList']
		serviceList[index]["flag"] = !itemFlag;
		this.setState({...this.state, serviceList: serviceList})

  }
  handleClose(index,itemFlag){
    console.log("close")
    let serviceList = this.state['serviceList']
		serviceList[index]["flag"] = !itemFlag;
		this.setState({...this.state, serviceList: serviceList})
  }

  render() {
    // console.log(window.location.pathname);
    let headerName = window.location.pathname;

    return (
      <div>
        <Header />
        <SubHeader name={headerName} />
        <section className="services-section padding-full position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="comm-para mt-2 mb-md-4 mb-lg-5 mb-xl-5 mb-sm-3 mb-3 text-center aos-init aos-animate" data-aos="fade-up">
                  <div className="home-heading"><h2 id="h2_">Our Services</h2></div>
                  <p></p><p id="subHeading" className="comm-para mt-2 mb-5" style={{paddingLeft:'19vw'}}>Delivering Cutting-Edge Software Solutions and End-to-End IT Services.</p>
                  <p></p>
                </div>
              </div>
            </div>
            <Grid container spacing={3}>
              {this.state.serviceList?.map((item, index) => (

                <Grid item xs={12} md={4} >
                  <div className="service-part app-serv hover-border">
                    <div className="hover-services">
                      <img src={item.logo} alt="" className="img-fluid hover-img" />
                      <img src={item.logo} alt="" className="img-fluid out-hover" />
                    </div>
                    <div className="services_head">{item.heading}</div>
                    <p>{item.description}</p>
                   
                    <Button 
                    className="" onClick={()=> this.handleOpen(index,item.flag)}>Read More</Button>
                    <Modal
                      open={item.flag}
                      key={item.id}
                      onClose={()=> this.handleOpen(index,item.flag)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      height="500px" width="100px" sx={{height:'2rem'}}
                    >
                      <div style={{backgroundColor:'#fff', height:'22rem', width: '38rem',borderStyle: 'groove'}} className='modalDiv'>
                      
                      <div className="_modalSer ce" >
                        {item.heading}
                        </div> <br />
                       <p className='modalDescription'>{item.description}</p>
                      </div>
                      
                      </Modal>
                  </div>

                </Grid>
              ))
              }

            </Grid>
          </div>
        </section>
     
      </div>
    )
  }
}

export default Services