// HomePage.js
import React, { useState, useEffect } from 'react';
import './home.css';
import '../../resources/css/style.css'
import '../../resources/css/aos.css'
import KeyTech from '../../Components/KeyTech/keyTech';
import Banner from '../../Components/Banner/Banner';
import Newslatter from "../../Components/Newslatter/newsLatter"
import CompanyIntro from '../../Components/CompanyIntro/CompanyIntro';
import Header from '../Header/header';
import homeApi from '../../Api/homeApi';
import commonFunction from '../../Components/commonFunction';
// import ClientFeebBack from '../../Components/ClientFeebBack/ClientFeebBack';
import CustomerChoose from '../../Components/CustomerChoose/CustomerChoose';
import OurService from '../../Components/OurService/ourService';


const Home = () => {
  const [webData, setWebData] = useState();
  

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const FetchApiData = async () =>{
      try{
      
          const response = await homeApi.webDetails();
          console.log(response);
          setWebData(response);
          commonFunction.setWebsiteData(response.data);
      }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  FetchApiData();
  }, []);

  return (
    <div>
      <Header />
      <Banner />
      {/* who we are */}
      <CompanyIntro data={webData} />

      {/* our services/ create service page and put this section there */}
      <OurService /> 

      {/* choose-section */}
      <CustomerChoose />

      {/* keytech section */}
      <KeyTech />

      {/* client-feedback */}
      {/* <ClientFeebBack /> */}
      {/* newslatter */}
      <Newslatter />
    </div>
  );
};
export default Home;