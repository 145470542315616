import React, { useState, Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './Navbar.css'
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import img from '../../images/logo.png'
const Navbar = () => {
  const drawerWidth = 240;
  let isHomePage = (window.location.pathname === '/Home' || window.location.pathname === '/' );
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  
  return (
    <>
      <Fragment>
        <nav className={
          (colorChange || !isHomePage)
            ? "navbar colorChange"
            : "navbar"
        } >
          <div className="container">
            <div className="logo">
            <Link to="/"><img src={img} alt='' width='90%' height='50px' /></Link>
            </div>
            <div className="nav-elements">
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About</NavLink>
                </li>
                <li>
                  <NavLink to="/service">Services</NavLink>
                </li>
                <li>
                <NavLink to="/contactUs#contactId"><Button className='btn-animation button blue-solid-btn bannerBtnN'><b>Contact Us</b></Button></NavLink>
                </li>
                {/* <MenuIcon /> */}
              </ul>
            </div>
          </div>
        </nav>
      </Fragment>
    </>
    // source https://www.codevertiser.com/reactjs-responsive-navbar/
  )
}

export default Navbar